<template>
  <div class="softwareComponentCommandList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(softwareComponentCommands)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(softwareComponentCommands)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="commandTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div
              style="white-space: nowrap;"
            >
              {{ props.dataItem.command }}
            </div>
          </td>
        </template>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="commandFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="commandFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Command"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('commandFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="commandTypeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="commandTypeFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Type"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('commandTypeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="roleFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="roleFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Role"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('roleFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <div
          slot="subFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="subFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Type"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('subFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>

        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group-vertical">
              <div class="btn-group">
                <button
                  v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')"
                  class="btn btn-primary btn-sm"
                  @click="showSidebarEdit(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="edit"
                  />
                  <span>Edit</span>
                </button>
                <button
                  v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')"
                  class="btn btn-secondary btn-sm"
                  @click="openConfirmModal(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2 gray"
                    icon="trash"
                  />
                  <span class="pr-3">Remove</span>
                </button>
              </div>
              <button
                v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')"
                class="btn btn-primary btn-sm"
                @click="showDescriptionModal(props.dataItem.description)"
              >
                <span>Description</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <button
        class="btn btn-secondary mt-4"
        @click="$router.push('/software-component-types');"
      >
        <font-awesome-icon
          class="mr-1"
          icon="caret-left"
        />
        <span>
          {{ $t('back') }}
        </span>
      </button>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <SoftwareComponentCommandAdd
          v-if="addingElement"
          :component-type-id="typeId"
          @reload="reloadSoftwareComponentCommands"
        />
        <SoftwareComponentCommandEdit
          v-else
          :software-component-command="softwareComponentCommand"
          @reload="reloadSoftwareComponentCommands"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="softwareComponentCommand">
        {{ $t('sureToDelete', {'0' : softwareComponentCommand.name}) }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeSoftwareComponentCommand()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
    <SweetModal
      ref="description"
      :title="$t('description')"
      blocking
      class="overflowHidden"
    >
      <div
        v-if="description"
        style="max-height: 550px; overflow: scroll;"
      >
        <div
          style="white-space: pre-line;"
        >
          {{ description }}
        </div>
      </div>
      <button
        slot="button"
        class="btn btn-secondary float-right mb-3"
        @click="$refs.description.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import SoftwareComponentCommandAdd from '@/components/SoftwareComponent/SoftwareComponentCommandAdd';
import SoftwareComponentCommandEdit from '@/components/SoftwareComponent/SoftwareComponentCommandEdit';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "SoftwareComponentCommandList",
  components: {
    SweetModal,
    Sidebar,
    SoftwareComponentCommandAdd,
    SoftwareComponentCommandEdit
  },
  mixins: [
    kendoGridMixin,
    authenticationMixin
  ],
  props: {
    typeId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      softwareComponentCommand: null,
      softwareComponentCommands: null,
      showSidebar: false,
      addingElement: false,
      deleteIndex: null,
      description: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'key',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "key",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'id',
          filterable: false,
          filter: 'text',
          title: 'ID',
        },
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: this.$t('name'),
          filterCell: 'nameFilter',
        },
        {
          field: 'command',
          filterable: true,
          filter: 'text',
          title: this.$t('deviceDetailComponent.command'),
          filterCell: 'commandFilter',
          cell: 'commandTemplate'
        },
        {
          field: 'commandType',
          filterable: true,
          filter: 'text',
          title: 'Command Type',
          filterCell: 'commandTypeFilter',
        },
        {
          field: 'role',
          filterable: true,
          filter: 'text',
          title: 'Role',
          filterCell: 'roleFilter',
        },
        {
          field: 'softwareComponentSubType',
          filterable: true,
          filter: 'text',
          title: 'Sub Type',
          filterCell: 'subFilter',
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          hidden: !(this.authenticationHasRole('secondlevel') || (this.authenticationHasRole('scope_staff') && this.authenticationHasRole('admin'))),
          width: '190px'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t('softwareComponent.softwareComponentCommands')
    }
  },
  created () {
    this.getSoftwareComponentCommands();
  },
  methods: {
    //sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showAddComponentCommand () {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentSoftwareComponentCommand(emitValues);
      this.addingElement = false;
      this.show();
    },
    showDescriptionModal (val) {
      this.description = val
      this.$refs.description.open();
    },
    setCurrentSoftwareComponentCommand (softwareComponentCommand) {
      this.softwareComponentCommand = Object.assign({}, softwareComponentCommand); 
    },
    //end sidebar

    //api
    reloadSoftwareComponentCommands () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getSoftwareComponentCommands();
    },
    openConfirmModal (data) {
      this.setCurrentSoftwareComponentCommand(data);
      this.deleteIndex = data.id;
      this.$refs.confirmDelete.open();
    },
    removeSoftwareComponentCommand () {
      this.axios.delete(`/SoftwareComponent/DeleteSoftwareComponentCommand?id=${ this.deleteIndex }`)
      .then(() => {
        this.$snotify.success(this.$t('softwareComponent.commandDeletedSuccessfully'));
        this.$emit("reloadAuditLogs");
        this.reloadSoftwareComponentCommands();
      })
      .finally(() => {
        this.deleteIndex = null;
        this.$refs.confirmDelete.close();
      });
    },
    getSoftwareComponentCommands () {
      this.loading = true;
      this.axios.get(`/SoftwareComponent/GetSoftwareComponentCommands?typeId=${ this.typeId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.softwareComponentCommands = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }     
  }
}
</script>
<style>
.softwareComponentCommandList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}

	.softwareComponentCommandList .k-grid-header col:nth-of-type(1) {
		width: 7%
	}

	.softwareComponentCommandList .k-grid-table col:nth-of-type(1) {
		width: 7%
	}

</style>